<template>
  <div class="reservation-add-driver-hotel">
    <div class="reservation-add-driver-hotel--content">
      <HotelRequirementForm
        :trip-id="tripId"
        :hotel-information="hotelInformation"
      />
      <v-divider />
      <DriverHotelInfoForm
        :trip-id="tripId"
        :hotel-information="hotelInformation"
        :disabled="!$store.getters['tripHotelRequirement/isRequired']"
      />
    </div>
    <v-btn
      class="reservation-add-driver-hotel--action-btn"
      color="primary"
      style="border-radius: 0"
      @click="submit"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import DriverHotelInfoForm from './DriverHotelInfoForm.vue'
import HotelRequirementForm from './HotelRequirementForm.vue'

export default {
  components: { DriverHotelInfoForm, HotelRequirementForm },
  props: {
    tripId: { type: Number, default: 0 },
    hotelInformation: { type: Object, default: () => undefined },
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('tripHotelRequirement/submit')
        await this.$store.dispatch('tripHotelInformation/submit')
      } catch (e) {
        this.$store.dispatch('app/showAlert', {
          message: 'Error saving hotel information and requirements',
          type: 'error',
        })
        return
      }
      EventBus.$emit('refresh-detail')
      this.close()
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-add-driver-hotel {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
    margin: 0;
  }
}
</style>
