var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.addressName)?_c('CRAddressSelector',{staticClass:"margin-t-n2",attrs:{"label":"Hotel Address","placeholder":"Type to search by name or address","display-field":"addressName","legacy-border":"","error-messages":_vm.addressErrors,"disabled":_vm.disabled},on:{"place-selected":function($event){return _vm.$store.dispatch('tripHotelInformation/selectAddress', $event)},"place-cleared":function($event){return _vm.$store.dispatch('tripHotelInformation/setAddress', null)}}}):_c('CRInput',{staticClass:"margin-t-n2 margin-b-n6",attrs:{"value":_vm.addressName || '',"label":"Hotel Address","type":"text","clear-icon":"replay","clearable":true,"readonly":true,"disabled":_vm.disabled},on:{"click:clear":function($event){return _vm.$store.dispatch('tripHotelInformation/setAddress', null)}}}),_c('p',{staticClass:"font-12 margin-t-n1 margin-b-6"},[_vm._v(" Make sure to enter a complete address, including street name and number. ")]),_c('CRInput',{staticClass:"margin-t-n2",attrs:{"value":_vm.hotelName,"label":"Hotel Name","placeholder":"Hotel Name","type":"text","rules":[
      (v) => _vm.validateLength(v, 50) || 'Must be less than 50 characters',
    ],"disabled":_vm.disabled},on:{"input":function($event){return _vm.$store.dispatch('tripHotelInformation/setHotelName', $event)}}}),_c('CRInput',{staticClass:"margin-t-n2",attrs:{"value":_vm.confirmationNumber,"label":"Booking Number","placeholder":"e.g. BYOC764209","type":"text","rules":[
      (v) => _vm.validateLength(v, 50) || 'Must be less than 50 characters',
    ],"disabled":_vm.disabled},on:{"input":function($event){return _vm.$store.dispatch('tripHotelInformation/setConfirmationNumber', $event)}}}),_c('CRInput',{staticClass:"margin-t-n2",attrs:{"value":_vm.nameOnReservation,"label":"Name on the Booking","placeholder":"e.g. William Green or Wedding bus driver","type":"text","rules":[
      (v) => _vm.validateLength(v, 50) || 'Must be less than 50 characters',
    ],"disabled":_vm.disabled},on:{"input":function($event){return _vm.$store.dispatch('tripHotelInformation/setNameOnReservation', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }