<template>
  <div>
    <v-switch
      :input-value="isHotelRequired"
      label="Is Hotel Required?"
      @change="$store.dispatch('tripHotelRequirement/setIsRequired', $event)"
    />
    <v-layout row wrap>
      <v-flex xs6 class="padding-r-1">
        <CRInput
          id="hotel-requirement-form-check-in-date"
          :value="checkIn"
          label="Check In Date"
          :disabled="!isHotelRequired"
          type="date"
          @input="$store.dispatch('tripHotelRequirement/setCheckIn', $event)"
        />
      </v-flex>
      <v-flex xs6 class="padding-r-1">
        <CRInput
          id="hotel-requirement-form-check-out-date"
          :value="checkOut"
          label="Check Out Date"
          :disabled="!isHotelRequired"
          type="date"
          @input="$store.dispatch('tripHotelRequirement/setCheckOut', $event)"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/state/helpers'
import { deepClone } from '@/utils/deepClone'

export default {
  computed: {
    ...authComputed,
    ...mapState('reservations', {
      reservationsCache: (state) => {
        return state.reservationsCache
      },
    }),
    reservationId() {
      return this.$route.params.id
    },
    reservation() {
      return this.reservationsCache[this.reservationId]
    },
    isHotelRequired() {
      return this.$store.getters['tripHotelRequirement/isRequired']
    },
    checkIn() {
      return this.$store.getters['tripHotelRequirement/checkIn']
    },
    checkOut() {
      return this.$store.getters['tripHotelRequirement/checkOut']
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      const tripId = this.reservation?.tripId
      let hotelRequirement = deepClone(this.reservation.hotelRequirement) || {
        tripId,
        isRequired: false,
      }

      const existingHotelRequirement = this.$store.getters[
        'tripHotelRequirement/hotelRequirement'
      ]
      if (existingHotelRequirement?.tripId === tripId) {
        hotelRequirement = existingHotelRequirement
      }
      this.$store.dispatch(
        'tripHotelRequirement/setHotelRequirement',
        hotelRequirement
      )
    },
  },
}
</script>
