<template>
  <div>
    <CRAddressSelector
      v-if="!addressName"
      class="margin-t-n2"
      label="Hotel Address"
      placeholder="Type to search by name or address"
      display-field="addressName"
      legacy-border
      :error-messages="addressErrors"
      :disabled="disabled"
      @place-selected="
        $store.dispatch('tripHotelInformation/selectAddress', $event)
      "
      @place-cleared="$store.dispatch('tripHotelInformation/setAddress', null)"
    />
    <CRInput
      v-else
      :value="addressName || ''"
      class="margin-t-n2 margin-b-n6"
      label="Hotel Address"
      type="text"
      clear-icon="replay"
      :clearable="true"
      :readonly="true"
      :disabled="disabled"
      @click:clear="$store.dispatch('tripHotelInformation/setAddress', null)"
    />
    <p class="font-12 margin-t-n1 margin-b-6">
      Make sure to enter a complete address, including street name and number.
    </p>
    <CRInput
      :value="hotelName"
      class="margin-t-n2"
      label="Hotel Name"
      placeholder="Hotel Name"
      type="text"
      :rules="[
        (v) => validateLength(v, 50) || 'Must be less than 50 characters',
      ]"
      :disabled="disabled"
      @input="$store.dispatch('tripHotelInformation/setHotelName', $event)"
    />
    <CRInput
      :value="confirmationNumber"
      class="margin-t-n2"
      label="Booking Number"
      placeholder="e.g. BYOC764209"
      type="text"
      :rules="[
        (v) => validateLength(v, 50) || 'Must be less than 50 characters',
      ]"
      :disabled="disabled"
      @input="
        $store.dispatch('tripHotelInformation/setConfirmationNumber', $event)
      "
    />
    <CRInput
      :value="nameOnReservation"
      class="margin-t-n2"
      label="Name on the Booking"
      placeholder="e.g. William Green or Wedding bus driver"
      type="text"
      :rules="[
        (v) => validateLength(v, 50) || 'Must be less than 50 characters',
      ]"
      :disabled="disabled"
      @input="
        $store.dispatch('tripHotelInformation/setNameOnReservation', $event)
      "
    />
  </div>
</template>

<script>
import CRAddressSelector from '@/cr/components/CRAddressSelector.vue'
import { validateLength } from '@/utils/validators'
import { deepClone } from '@/utils/deepClone'
import { EventBus } from '@/utils/event-bus'

export default {
  components: { CRAddressSelector },
  props: {
    tripId: { type: Number, default: 0 },
    hotelInformation: { type: Object, default: () => undefined },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      addressErrors: [],
      validateLength,
    }
  },
  computed: {
    address() {
      return this.$store.getters['tripHotelInformation/address'] || null
    },
    confirmationNumber() {
      return this.$store.getters['tripHotelInformation/confirmationNumber']
    },
    nameOnReservation() {
      return this.$store.getters['tripHotelInformation/nameOnReservation']
    },
    hotelName() {
      return this.$store.getters['tripHotelInformation/hotelName']
    },
    addressName() {
      return this.address?.addressName
    },
  },
  mounted() {
    const hotelInformation = deepClone(this.hotelInformation) || {
      hotelInformationId: null,
      address: null,
      hotelName: null,
      confirmationNumber: null,
      nameOnReservation: null,
    }
    hotelInformation.tripId = this.tripId
    this.$store.dispatch(
      'tripHotelInformation/setHotelInformation',
      hotelInformation
    )
  },
}
</script>
